<template>
  <div class="container">
    <div class="mx-auto loginContent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        > RESET PASSWORD
      </div>
      <div class="card text-center">
        <div class="card-body">
          <h2 class="text-primary">Reset Your Password</h2>
          <hr />
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.resetPassForm"
          />
          <ValidationObserver ref="resetPassForm">
            <form @submit.prevent="onSubmit">
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|min:8|atLeastOneLowercase|atLeastOneUppercase|atLeastOneNumber|atLeastOneSpecialCharacter"
                    name="Password"
                    id="resetPassword1"
                    vid="pass"
                    type="password"
                    placeholder="Enter your new password"
                    v-model="resetPassword1"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|confirmed:pass"
                    name="Confirm Password"
                    id="resetPassword2"
                    type="password"
                    placeholder="Confirm your new password"
                    v-model="resetPassword2"
                  />
                </div>
              </div>
              <button type="submit" class="btn btn-primary">
                CHANGE MY PASSWORD
              </button>
              <p class="mt-3">
                <span style="color: red;">*</span> Indicates a required field
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import ProfileService from "@/services/ProfileService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "ResetPassword",
  title: "Reset Password",
  data() {
    return {
      resetPassword1: "",
      resetPassword2: "",
      errors: []
    };
  },
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert
  },
  methods: {
    async onSubmit() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const confirmationCode = this.$route.query.verificationCode;
      const password = this.resetPassword1;
      const confirmPassword = this.resetPassword2;
      const username = this.$route.query.username;
      this.$refs.resetPassForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.resetPassForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.resetPassForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new ProfileService(tenantId);
            const response = await profileService.forgotPasswordConfirmation({
              username,
              confirmationCode,
              password,
              confirmPassword
            });
            if (response?.statusCode == "Success" && response?.user) {
              const errorMessage = await this.$store.dispatch("auth/login", {
                loginUsername: username,
                loginPassword: password,
                authSource: "local"
              });
              if (errorMessage) {
                this.$store.commit("alert/setErrorAlert", {
                  type: "alert-danger",
                  message: errorMessage,
                  layer: "public"
                });
              }
            } else {
              const error = response.messages[0].substr(
                response.messages[0].indexOf("{")
              );
              const jsonError = JSON.parse(error);
              let errorMessage = "";

              if (
                jsonError.message.startsWith(
                  "Password change confirmation failed for user"
                )
              ) {
                errorMessage =
                  "The password reset link has expired. Navigate to the Forgot Your Login Information page to request a new password reset link.";
              } else if (jsonError.message === "Previously used password") {
                jsonError.message = "Do not use last five passwords";
              }

              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message:
                  errorMessage != ""
                    ? errorMessage
                    : `Password Reset Failure: ${jsonError.message}`,
                layer: "public"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.card-body {
  margin-bottom: 160px;
}
</style>
